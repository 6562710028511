declare var MktoForms2: any

import * as React from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import MarketoForm from 'components/modal-manager/forms/components/marketo-form'
import { lightBlue, grayBackground } from 'shared/colors'
import Check from 'icons/check.svg'
import SentIcon from './sent.svg'
import { laptop } from 'shared/media-queries'
import {
	MarketoUrl,
	MarketoCode,
} from 'components/modal-manager/forms/constants'

const Container = styled.div`
	max-width: 100%;
	width: 400px;
	background-color: ${grayBackground};
	${laptop} {
		width: 315px;
	}
`
const BlueBar = styled.div`
	height: 10px;
	background-color: ${lightBlue};
`
const Content = styled.div`
	padding: 30px;
	${laptop} {
		padding: 20px;
	}
`
const Wording = styled.div`
	padding: 10px 0;
	font-size: 24px;
	font-weight: 700;
	${laptop} {
		font-size: 20px;
		padding-top: 0;
	}
`
const ReasonContainer = styled.div`
	display: flex;
	font-size: 18px;
	font-weight: 700;
	align-items: center;
	margin: 5px 0;
`
const StyledCheck = styled(Check)`
	margin-right: 20px;
`
const StyledSentIcon = styled(SentIcon)`
	margin-top: 20px;
`

const Form = styled(MarketoForm)`
	&.mktoForm {
		@media (max-width: 480px) {
			padding: 0px !important;
		}
		.mktoButtonRow {
			text-align: center !important;
		}
	}
	&.mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
		margin-left: 20px !important;
		${laptop} {
			margin-left: 4px !important;
			width: 270px !important;
			max-width: 270px !important;
		}
		@media (max-width: 480px) {
			width: 270px !important;
			max-width: 270px !important;
		}
		@media (max-width: 480px) {
			margin: 15px auto !important;
		}
	}
	.mktoFieldWrap.mktoRequiredField {
		@media (max-width: 480px) {
			float: left !important;
		}
	}
	.mktoGutter .mktoHasWidth {
		@media (max-width: 480px) {
			display: none !important;
		}
	}
	&.mktoForm .mktoHasWidth.mktoLayoutLeft {
		@media (max-width: 480px) {
			padding: 10px !important;
		}
	}
	.mktoFormRow {
		${laptop} {
			width: 270px !important;
		}
		@media (max-width: 480px) {
			width: 270px !important;
		}
	}
	.mktoButtonWrap {
		margin-left: -20px !important;
		${laptop} {
			margin-left: -2px !important;
		}
	}
`

const Reason: React.FC = ({ children }) => (
	<ReasonContainer>
		<StyledCheck />
		{children}
	</ReasonContainer>
)

const Subscribe: React.FC = () => {
	const [submitted, updateSubmitStatus] = useState(false)
	useEffect(() => {
		// eslint-disable-next-line
		MktoForms2.loadForm(MarketoUrl, MarketoCode, 1843)
		// eslint-disable-next-line
		MktoForms2.whenReady(form => {
			form.onSuccess(() => {
				updateSubmitStatus(true)
				// return false to prevent the submission handler from taking the lead to the follow up url.
				return false
			})
		})
	}, [])

	return (
		<form>
			<Container>
				<BlueBar />
				<Content>
					<Wording>
						Join over 1 Million construction professionals who subscribe to our
						monthly newsletter.
					</Wording>
					<Reason>Industry content</Reason>
					<Reason>2-minute read</Reason>
					<Reason>Free, forever</Reason>
					<Reason>{`<`} 0.2% unsubscribe</Reason>
					{submitted ? <StyledSentIcon /> : <Form id="mktoForm_1843" />}
				</Content>
			</Container>
		</form>
	)
}

export default Subscribe
